import { AfterViewInit, Component, OnInit, ElementRef } from '@angular/core';
import { Auth } from 'aws-amplify';
import SwaggerUI from 'swagger-ui';

import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-swagger-ui',
  templateUrl: './swagger-ui.component.html',
  styleUrls: ['./swagger-ui.component.css']
})
export class SwaggerUiComponent implements AfterViewInit, OnInit {

  accessToken: Object;
  identityId: Object;
  envname: String;
  env: String;
  backColor: String;
  apihost: String;

  constructor(private el: ElementRef) {
    this.envname = environment.envname;
    this.env = environment.env;
    this.backColor = environment.backColor;
    this.apihost = environment.amplifyConfig.API.endpoints[0].endpoint;
  }

  ngOnInit() {
    Auth.currentSession().then(res => {
      this.accessToken = res.getAccessToken().getJwtToken();
    });
    Auth.currentCredentials().then(info => {
      this.identityId = info.identityId;
    })
  }


  ngAfterViewInit() {

    var ui = SwaggerUI({
      url: 'https://public-bucket-for-swagger.s3.ap-southeast-1.amazonaws.com/'+this.env+'-morning-razer-api-swagger.json',
      domNode: this.el.nativeElement.querySelector('.swagger-container'),
      deepLinking: true,
      onComplete: () => {
        ui.preauthorizeApiKey("BearerAuth", this.accessToken);
      },
      requestInterceptor: req => {
        if (!req.url.includes('morning-razer-api-swagger.json') ) {
          var re = /(http[s]?:\/\/.*?)\//;
          var apiUrl = req.url.replace(re, environment.amplifyConfig.API.endpoints[0].endpointWithHttp+"/");
          req.url = apiUrl;
          return req;
        }
      }
    });

  }

  backToTop() {
    document.getElementById('api-details').scrollIntoView();
  }

}
