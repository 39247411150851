import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { environment } from '../../environments/environment';
import { Ota } from './ota';
import { OtaStat } from './otastat';

@Injectable({
  providedIn: 'root'
})
export class OtaService {

  apiUrl = environment.amplifyConfig.API.endpoints[0].endpointWithHttp;

  constructor(private http: HttpClient) { }

  getOtas(token, from: Number, size: Number) {

    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.get<Ota[]>(this.apiUrl + '/ota?from='+from+'&pageSize='+size, { headers });
  }

  getOtaStat(token, from: Number, size: Number) {

    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.get<OtaStat[]>(this.apiUrl + '/machine/ota-stat?from='+from+'&pageSize='+size, { headers });
  }

  createOta(token, ota: FormData): Observable<Ota> {
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.post<Ota>(this.apiUrl + '/ota', ota, { headers });
  }

  updateOta(token, ota: Ota): Observable<Object[]> {
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.put<Object[]>(this.apiUrl + '/ota', ota, { headers });
  }

  deleteOta(token, fileName: String): Observable<Object[]> {
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.delete<Object[]>(this.apiUrl + '/ota/'+fileName, { headers });
  }
}
