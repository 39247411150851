export const environment = {
    envname:"DEV",
    env: "DEV",
    backColor:"#ea9e2c",
    amplifyConfig: {
        "aws_project_region": "ap-southeast-1",
        "Auth": {
            "identityPoolId": "ap-southeast-1:b482a9a1-6686-434f-b431-7ee11e2ae1d9",
            "region": "ap-southeast-1",
            "userPoolId": "ap-southeast-1_7lVTr8djL",
            "userPoolWebClientId": "7r4aksnkvce2ujqgv3nq16c3dg"
        },
        "API": {
            "endpoints": [{
                "name": "morning-api",
                "endpoint": "razer-morning-api-dev.akronym.link",
                "endpointWithHttp": "https://razer-morning-api-dev.akronym.link"
            }]
        }
    },
    production: false
};