import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Machine } from '../machine';
import { MachineFromApi, PumpFlowRate } from '../machine-from-api';
import { MachineStat } from '../machine-stat';
import { MachineWrapper } from '../machine-wrapper';
import { Observable, of } from 'rxjs';
import * as AWS from 'aws-sdk';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MachineService {

  apiUrl = environment.amplifyConfig.API.endpoints[0].endpointWithHttp;

  constructor(private http: HttpClient) { }

  getMachinesFromApi(token, from: Number, size: Number) {

    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.get<MachineFromApi[]>(this.apiUrl + '/machine?from='+from+'&pageSize='+size+'&fields=id', { headers });

  }

  getMachineStat(token) {

    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.get<MachineStat>(this.apiUrl + '/machine/stat', { headers });

  }

  search(token, from: Number, size: Number, search: String) {

    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.get<MachineFromApi[]>(this.apiUrl + '/machine?from='+from+'&pageSize='+size+'&fields=id&search='+search, { headers });

  }

  addMachine(token, machine: MachineFromApi): Observable<MachineFromApi> {
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.post<MachineFromApi>(this.apiUrl + '/machine', machine, { headers });
  }

  attachOta(token, machineId: String, otaFileName: String): Observable<MachineFromApi> {
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.put<MachineFromApi>(this.apiUrl + '/machine/'+machineId+'/ota?ota='+otaFileName, {}, { headers });
  }

  detachOta(token, machineId: String) {
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.delete<MachineFromApi>(this.apiUrl + '/machine/'+machineId+'/ota', { headers });
  }

  getMachines(creds, lastEvaluatedKey: any, connected: boolean): Observable<MachineWrapper> {

    const machineWrapper = new MachineWrapper;
    machineWrapper.machines = new Array<Machine>();

    var dynamodb = new AWS.DynamoDB({ credentials: creds, region: "ap-southeast-1", apiVersion: '2012-08-10' });

    var params;

    if(connected===null){
      params = {
        ExpressionAttributeValues: {
          ":m": {
            S: "M_"
           }
         }, 
        TableName: "machine_status",
        FilterExpression: "begins_with ( mac, :m )",
        Limit: 10,
        ExclusiveStartKey : lastEvaluatedKey
      };
    } else {
      params = {
        ExpressionAttributeValues: {
           ":c": {
             BOOL: true
           }
         }, 
        TableName: "machine_status",
        KeyConditionExpression : "connected = :c",
        Limit: 10,
        ExclusiveStartKey : lastEvaluatedKey
      };
    }

    dynamodb.scan(params, function (err, data) {
      if (err) {
        console.log(err);
      }
      else {
        
        data.Items.forEach(function (item) {
          var machine = new Machine();
          machine.mac = item.mac.S;
          machine.connected = item.connected.BOOL;

          if (item.status != undefined) {
            machine.status = item.status.S;
          } else {
            machine.status = "";
          }

          if (item.user != undefined) {
            machine.user = item.user.S;
          } else {
            machine.user = "";
          }

          if (item.phone != undefined) {
            machine.phone = item.phone.S;
          } else {
            machine.phone = "";
          }

          machineWrapper.machines.push(machine);
        });

        machineWrapper.lastKeyEvaluated = data.LastEvaluatedKey;

      }
    });

    return of(machineWrapper);
  }

  searchMachines(creds, lastEvaluatedKey: any, search: string): Observable<MachineWrapper> {

    const machineWrapper = new MachineWrapper;
    machineWrapper.machines = new Array<Machine>();

    var dynamodb = new AWS.DynamoDB({ credentials: creds, region: "ap-southeast-1", apiVersion: '2012-08-10' });

    var params = {
      ExpressionAttributeValues: {
        ":m": {
          S: "M_"
         }
       }, 
      TableName: "machine_status",
      FilterExpression: "begins_with ( macx, :m )",
      Limit: 10,
      ExclusiveStartKey : lastEvaluatedKey
    };

    dynamodb.scan(params, function (err, data) {
      if (err) {
        console.log(err);
      }
      else {
        
        data.Items.forEach(function (item) {
          var machine = new Machine();
          machine.mac = item.mac.S;
          machine.connected = item.connected.BOOL;

          if (item.status != undefined) {
            machine.status = item.status.S;
          } else {
            machine.status = "";
          }

          if (item.user != undefined) {
            machine.user = item.user.S;
          } else {
            machine.user = "";
          }

          if (item.phone != undefined) {
            machine.phone = item.phone.S;
          } else {
            machine.phone = "";
          }

          machineWrapper.machines.push(machine);
        });

        machineWrapper.lastKeyEvaluated = data.LastEvaluatedKey;

      }
    });

    return of(machineWrapper);
  }

  updatePumpFlowRate(token, machineId: String, pumpFlowRate: PumpFlowRate) {
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.put(this.apiUrl + '/machine/'+machineId+'/pump-flow-rate', pumpFlowRate, { headers });
  }
    
  resetBrewCount(token, machineId: String){
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);
    return this.http.put(this.apiUrl + '/machine/'+machineId+'/resetBrewCount', { headers });
  }


}
