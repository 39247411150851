import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Roaster } from '../Roaster';
import * as AWS from 'aws-sdk';
import { String } from 'aws-sdk/clients/appstream';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoasterService {

  apiUrl = environment.amplifyConfig.API.endpoints[0].endpointWithHttp;

  constructor(private http: HttpClient) { }

  getRoasters(token, from: Number, size: Number) {

    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.get<Roaster[]>(this.apiUrl + '/admin/roaster?from='+from+'&pageSize='+size+'&fields=name', { headers });
    
  }

  getRoaster(token, id: number) {

    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.get<Roaster>(this.apiUrl + '/roaster/'+id, { headers });
    
  }

  addRoaster(token, roaster: Roaster): Observable<Roaster> {
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.post<Roaster>(this.apiUrl + '/admin/roaster', roaster, { headers });
  }

  uploadImage(image, creds, key: String): Promise<String> {

    return new Promise<String>((resolve, reject) => {

      var s3 = new AWS.S3({ credentials: creds, region: "ap-southeast-1", apiVersion: '2006-03-01' });

      var buf = Buffer.from(image.replace(/^data:image\/\w+;base64,/, ""), 'base64');

      var params = { ACL: 'public-read', Bucket: 'public-bucket-for-files', Key: key, Body: buf, ContentType: 'image/jpeg' };

      s3.upload(params, function (err, data) {
        if (err) {
          throw new Error(err);
        }
        if (data) {
          resolve(data.Location);
        }
      });

    });

  }

  updateFeatured(token, id:String, featured:Boolean, featuredOrder:Number){
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.put(this.apiUrl + '/admin/roaster/'+id+'/featured?featured='+featured+'&featuredOrder='+featuredOrder,{}, {headers});

  }

  getFeaturedRoasters(token) {

    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.get<Roaster[]>(this.apiUrl + '/roaster/featured', { headers });

  }

  updateRoaster(token, roaster: Roaster) {
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.put(this.apiUrl + '/roaster/'+roaster.id, roaster, { headers });
  }

  hideRoaster(token, id: number, hidden: boolean){
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.put(this.apiUrl + '/admin/roaster/'+id+'/hidden?hidden='+hidden, {}, { headers });
  }

  makeRoasterPartner(token, id: number, isPartner: boolean){
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.put(this.apiUrl + '/admin/roaster/'+id+'/partner?partner='+isPartner, {}, { headers });
  }

  changeStatus(token, id: number, status: string){
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.put(this.apiUrl + '/admin/roaster/'+id+'/approve?status='+status, {}, { headers });
  }

  search(token, search: string, from: number, size: number){
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.get<Roaster[]>(this.apiUrl + '/admin/roaster/search?search='+encodeURI(search)+'&from='+from+'&pageSize='+size+'&fields=name', { headers });
  }
}
