import { Component, OnInit } from '@angular/core';
import { Auth } from 'aws-amplify';
import { MachineService } from 'src/app/machine/service/machine.service';
import { UserService } from 'src/app/profile/user.service';
import { RecipeService } from 'src/app/recipe/service/recipe.service';
import { CapsulesService } from 'src/app/capsule/service/capsules.service';
import { interval, Subscription } from 'rxjs';
import { OtaService } from 'src/app/ota/ota.service';
import { Ota } from '../../../ota/ota'
import { OtaStat } from '../../../ota/otastat'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  subscription: Subscription;

  source = interval(60000);

  accessToken: Object;
  machineCount: Number
  machineOnlineCount: Number
  userCount: Number;
  userRecipeCount: Number;
  capsuleCount: Number;
  isLoading: Boolean;
  otas: Array<Ota>;
  otaStat: Array<OtaStat>;

  isLoading1: Boolean;
  isLoading2: Boolean;
  isLoading3: Boolean;
  isLoading4: Boolean;
  isLoading5: Boolean;
  isLoading6: Boolean;

  isFullScreen: Boolean;

  error: String;

  constructor(private machineService: MachineService,
    private userService: UserService,
    private recipeService: RecipeService,
    private capsulesService: CapsulesService,
    private otaService: OtaService) {

    this.subscription = this.source.subscribe(val => this.loadData());
  }

  ngOnInit(): void {
    this.loadData();
    this.loadOtaData();
  }

  loadData() {
    this.isLoading1 = true;
    this.isLoading2 = true;
    this.isLoading3 = true;
    this.isLoading4 = true;

    Auth.currentSession().then(res => {

      this.accessToken = res.getAccessToken().getJwtToken();

      this.machineService.getMachineStat(this.accessToken).subscribe(
        data => {
          this.machineCount = data.count;
          this.machineOnlineCount = data.onlineCount;
          this.isLoading1 = false;
        },
        error => {
          this.error = error.error.message;
        }
      )

      this.userService.getUserCount(this.accessToken).subscribe(
        data => {
          this.userCount = data;
          this.isLoading2 = false;
        },
        error => {
          this.error = error.error.message;
        }
      )

      this.recipeService.getAllUserRecipeCount(this.accessToken).subscribe(
        data => {
          this.userRecipeCount = data;
          this.isLoading3 = false;
        },
        error => {
          this.error = error.error.message;
        }
      )

      this.capsulesService.getCapsuleCount(this.accessToken).subscribe(
        data => {
          this.capsuleCount = data;
          this.isLoading4 = false;
        },
        error => {
          this.error = error.error.message;
        }
      )

    })
  }

  loadOtaData() {

    this.isLoading5 = true;
    this.isLoading6 = true;

    Auth.currentSession().then(res => {

      this.accessToken = res.getAccessToken().getJwtToken();

      this.otaService.getOtas(this.accessToken, 0, 8).subscribe(
        data => {
          this.otas = this.base64ToText(<Ota[]>data);
          this.isLoading5 = false;
        },
        error => {
          this.error = error.error.message;
        }
      )

      this.otaService.getOtaStat(this.accessToken, 0, 7).subscribe(
        data => {
          this.otaStat = <OtaStat[]>data;
          this.isLoading6 = false;
        },
        error => {
          this.error = error.error.message;
        }
      )


    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  base64ToText(otas: Ota[]) {
    otas.forEach(function (ota) {
      ota.comments = atob(ota.comments);

    });
    return otas;
  }

}
