import { Roaster } from '../../roaster/Roaster';

export class Recipe {

  public id: number;
  public name: any;
  public temperature: any;
  public waterOutput: any;

  public isDefault: boolean;
  public isMachineDefault: boolean;
  public isNew: boolean;
  public authorName: string;

  public roaster: Roaster;

}
