import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RecipeService } from '../service/recipe.service';
import { Auth } from 'aws-amplify';
import { RecipeWrapper } from '../entities/RecipeWrapper';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.css']
})
export class DefaultComponent implements OnInit {

  accessToken: Object;
  recipes: RecipeWrapper[];
  error: String;
  recipeCount: number;
  pageSizes = [5, 10, 15]
  pageSize = this.pageSizes[0];
  pageNumbers: number[]
  currentPageNumber: number;
  pageSubsetSize = 3;
  pageSubsetStartIndex: number;
  pageSubsetEndIndex: number;
  isPagePrevBtnEnabled: boolean;
  isPageNextBtnEnabled: boolean;
  isLoading; Boolean;

  constructor(private service: RecipeService, private ref: ChangeDetectorRef, private router: Router) { }

  ngOnInit(): void {

    this.isLoading = true;

    Auth.currentSession().then(res => {

      this.accessToken = res.getAccessToken().getJwtToken();

      this.service.getDefaultRecipeCount(this.accessToken).subscribe(
        data => {
          this.recipeCount = data as number;
          this.pageNumbers = this.getPageNumbers(this.recipeCount, this.pageSize);
          this.initPagination();
        },
        error => {
          this.error = "Unable to connect to the service.";
        }
      )
      this.getPageData(0, this.pageSize);
    })

  }

  initPagination() {
    this.pageSubsetStartIndex = 0;
    this.pageSubsetEndIndex = this.pageSubsetSize;
    this.isPagePrevBtnEnabled = false;
    this.isPageNextBtnEnabled = true;

    if (this.pageSubsetEndIndex - 1 >= this.pageNumbers[this.pageNumbers.length - 1]) {
      this.isPageNextBtnEnabled = false;
    }
  }

  getPageData(from: number, size: number) {
    this.currentPageNumber = from;
    this.service.getDefaultRecipes(this.accessToken, from, size).subscribe(
      data => {
        var recipeWrappers = <RecipeWrapper[]>data;
        this.recipes = <RecipeWrapper[]>data;
        if (from == 0) {
          this.isLoading = false;
        }
      },
      error => {
        this.error = "Unable to connect to the service.";
      }
    )
  }

  changePageSize(size: number) {
    this.pageSize = size;
    this.pageNumbers = this.pageNumbers = this.getPageNumbers(this.recipeCount, size);
    this.getPageData(0, size);
    this.initPagination();
  }

  getPrevPageSubSet() {
    if (this.pageSubsetStartIndex - this.pageSubsetSize >= 0) {
      this.pageSubsetStartIndex = this.pageSubsetStartIndex - this.pageSubsetSize;
      this.pageSubsetEndIndex = this.pageSubsetEndIndex - this.pageSubsetSize;
      this.isPageNextBtnEnabled = true;
      this.getPageData(this.pageSubsetEndIndex - 1, this.pageSize);
    }

    if (this.pageSubsetEndIndex - this.pageSubsetSize == 0) {
      this.isPagePrevBtnEnabled = false;
    }
  }

  getNextPageSubSet() {
    if (this.pageSubsetStartIndex + this.pageSubsetSize <= this.pageNumbers[this.pageNumbers.length - 1]) {
      this.pageSubsetStartIndex = this.pageSubsetStartIndex + this.pageSubsetSize;
      this.pageSubsetEndIndex = this.pageSubsetEndIndex + this.pageSubsetSize;
      this.isPagePrevBtnEnabled = true;
      this.getPageData(this.pageSubsetStartIndex, this.pageSize);
    }

    if (this.pageSubsetEndIndex - 1 >= this.pageNumbers[this.pageNumbers.length - 1]) {
      this.isPageNextBtnEnabled = false;
    }

  }

  getPageNumbers(recipeCount: number, pageSize: number) {
    let pageCount = Math.ceil(recipeCount / pageSize);
    return Array<number>(pageCount).fill(null).map((x, i) => i);
  }

  deleteRecipe(id: number) {
    this.service.deleteDefaultRecipe(this.accessToken, id).subscribe(
      data => {
        var btnId = "delete-close-confirm-btn" + id;
        let element: HTMLElement = document.querySelector('button[id="' + btnId + '"]') as HTMLElement;
        element.click();
        this.ngOnInit();
      },
      error => {
        var btnId = "delete-close-confirm-btn" + id;
        this.error = error.error.message;
        let element: HTMLElement = document.querySelector('button[id="' + btnId + '"]') as HTMLElement;
        element.click();
        this.ngOnInit();
      }
    )

  }

}
