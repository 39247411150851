import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import * as AWS from 'aws-sdk';
import { environment } from './../../../environments/environment';
import { Capsule } from '../capsule';

@Injectable({
  providedIn: 'root'
})
export class CapsulesService {

  apiUrl = environment.amplifyConfig.API.endpoints[0].endpointWithHttp;

  constructor(private http: HttpClient) { }

  getCapsules(token, from: Number, size: Number) {

    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.get<Capsule[]>(this.apiUrl + '/capsule?from='+from+'&pageSize='+size+'&fields=name', { headers });

  }

  getCapsuleCount(token) {

    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.get<Number>(this.apiUrl + '/capsule/count', { headers });

  }

  search(token, from: Number, size: Number, search: String, roasterId: Number) {

    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    if(search==null && roasterId==null) {
      return this.http.get<Capsule[]>(this.apiUrl + '/capsule?from='+from+'&pageSize='+size+'&fields=name&search='+search+'&roasterId='+roasterId, { headers });

    } else if(search==null && roasterId!=null) {
      return this.http.get<Capsule[]>(this.apiUrl + '/capsule?from='+from+'&pageSize='+size+'&fields=name&roasterId='+roasterId, { headers });

    } else if(search!=null && roasterId==null) {
      return this.http.get<Capsule[]>(this.apiUrl + '/capsule?from='+from+'&pageSize='+size+'&fields=name&search='+search, { headers });

    } else if(search!=null && roasterId!=null) {
      return this.http.get<Capsule[]>(this.apiUrl + '/capsule?from='+from+'&pageSize='+size+'&fields=name&search='+search+'&roasterId='+roasterId, { headers });
    }

    

  }

  addCapsule(token, capsule: Capsule): Observable<Capsule> {
    const headers = new HttpHeaders().set("authorization", "Bearer " + token);

    return this.http.post<Capsule>(this.apiUrl + '/capsule', capsule, { headers });
  }

}
