export class Machine {

    public mac: String;
    public connected: Boolean;
    public status: String;
    public user: String;
    public phone: String;
    public model : any;
    public firmwareVersion : any;
    public attachedOta : any;
    public lastKeyEvaluated : any;
    public createdDate : any;
}