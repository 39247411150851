<div class="d-flex pt-3 pb-2 mb-3 border-bottom">

  <h2 class="h2 mr-auto h1">Machine Default recipes</h2>

  <button type="button" class="btn btn-info btn-sm ml-1 mb-2" (click)="ngOnInit()" title="Refresh">
    <i-feather name="refresh-ccw"></i-feather>
  </button>

  <a *ngIf="downloadJsonHref" type="button" class="btn btn-primary btn-sm ml-1 mb-2" [href]="downloadJsonHref"
    download="machine-default-recipes.json" title="Download">
    <i-feather name="download"></i-feather>
  </a>

</div>

<div class="row">

  <div *ngIf="error" class="col-md-6">
    <div class="alert alert-danger" role="alert">
      {{error}}
    </div>
  </div>

  <div class="col-md-12">
    <p *ngIf="isLoading"> Loading<img src="../../../assets/loading_dots.gif" height="50px"></p>
    <p>The machine will be shipped with these recipes. <span *ngIf="recipes">({{recipes.length}} recipes)</span></p>
  </div>

  <div class="col-md-12 table-responsive">

    <table class="table table-hover shadow">
      <thead class="table-head">
        <tr>
          <th scope="col">#</th>
          <th scope="col">ID</th>
          <th scope="col">Name</th>
          <th scope="col">Water Output</th>
          <th scope="col">Mass Output</th>
          <th scope="col">Brew Time</th>
          <th scope="col">Temperature</th>
          <th scope="col">Bloom Duration</th>
          <th scope="col" colspan="3">Pressure Profile</th>
          <th scope="col">Author</th>
          <th scope="col">Capsule</th>
          <th scope="col">edit</th>
          <th scope="col">delete</th>
        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let rw of recipes; index as i">
          <td>{{i+1}}</td>
          <td>{{rw.recipe.id}}</td>
          <td>{{rw.recipe.name}} <span class="badge badge-success" *ngIf="rw.recipe.isNew">New</span></td>

          <td *ngIf="rw.recipe.waterOutput">{{rw.recipe.waterOutput}}</td>
          <td *ngIf="!rw.recipe.waterOutput"> - </td>

          <td *ngIf="rw.recipe.massOutput">{{rw.recipe.massOutput}}</td>
          <td *ngIf="!rw.recipe.massOutput"> - </td>

          <td *ngIf="rw.recipe.brewTime">{{rw.recipe.brewTime}}</td>
          <td *ngIf="!rw.recipe.brewTime"> - </td>

          <td>{{rw.recipe.temperature}}</td>

          <td *ngIf="rw.recipe.bloomDuration">{{rw.recipe.bloomDuration}}</td>
          <td *ngIf="!rw.recipe.bloomDuration"> - </td>

          <td *ngIf="rw.recipe.pressureProfile">
            <ul class="list-group">
              <li class="list-group-item">
                <small>Stage 01</small>
              </li>
              <li class="list-group-item">Pressure:
                {{rw.recipe.pressureProfile.stageDtos[0].pressure}}
              </li>
              <li class="list-group-item">
                Output:
                {{rw.recipe.pressureProfile.stageDtos[0].output}}
              </li>
            </ul>
          </td>
          <td *ngIf="rw.recipe.pressureProfile">
            <ul class="list-group">
              <li class="list-group-item">
                <small>Stage 02</small>
              </li>
              <li class="list-group-item">
                Pressure:
                {{rw.recipe.pressureProfile.stageDtos[1].pressure}}
              </li>
              <li class="list-group-item">
                Output:
                {{rw.recipe.pressureProfile.stageDtos[1].output}}
              </li>
            </ul>
          </td>
          <td *ngIf="rw.recipe.pressureProfile">
            <ul class="list-group">
              <li class="list-group-item">
                <small>Stage 03</small>
              </li>
              <li class="list-group-item">
                Pressure:
                {{rw.recipe.pressureProfile.stageDtos[2].pressure}}
              </li>
              <li class="list-group-item">
                Output:
                {{rw.recipe.pressureProfile.stageDtos[2].output}}
              </li>
            </ul>
          </td>

          <td *ngIf="!rw.recipe.pressureProfile"> - </td>
          <td *ngIf="!rw.recipe.pressureProfile"> - </td>
          <td *ngIf="!rw.recipe.pressureProfile"> - </td>

          <td>{{rw.recipe.roaster.name}} ({{rw.recipe.authorName}})</td>
          <td>{{rw.recipe.capsule}}</td>
          <td>
            
          </td>
          <td>
            <button type="button" class="btn btn-danger p-2" data-toggle="modal"
              [attr.data-target]="'#deleteModal'+rw.recipe.id">
              <i-feather name="trash-2"></i-feather>
            </button>

            <div class="modal fade" [attr.id]="'deleteModal'+rw.recipe.id" tabindex="-1"
              aria-labelledby="deleteModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Delete Recipe ( ID = {{rw.recipe.id}}) ?</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    Please click the Delete button to delete the recipes.
                  </div>
                  <div class="modal-footer">
                    <button type="button" [attr.id]="'delete-close-confirm-btn'+rw.recipe.id" class="btn btn-secondary"
                      data-dismiss="modal">Cancel</button>
                    <button (click)="deleteRecipe(rw.recipe.id)" type="button" class="btn btn-danger">Delete</button>
                  </div>
                </div>
              </div>
            </div>
          </td>

        </tr>

      </tbody>
    </table>

  </div>

</div>