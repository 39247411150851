import { Component, ChangeDetectorRef, OnInit, NgZone  } from '@angular/core';
import { onAuthUIStateChange, CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import Amplify from 'aws-amplify';

import { environment } from './../environments/environment';
import { UserService } from './profile/user.service';
import { MorningUser } from './profile/MorningUser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'admin-panel';
  envname = '';
  backColor = '';
  user: CognitoUserInterface | undefined;
  morningUser: MorningUser;
  defaultAvatarImg = '';
  error = 'xx';
  authState: AuthState;
  groups: any[];

  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  constructor(private ref: ChangeDetectorRef, private _ngZone: NgZone, private router: Router,private service: UserService) {
    if(environment.env=="dev"){
      this.favIcon.href = 'assets/favicon_dev.ico';
    } else if (environment.env=="local"){
      this.favIcon.href = 'assets/favicon_local.ico';
    }else if (environment.env=="stg"){
      this.favIcon.href = 'assets/favicon_stg.ico';
    } else {
      this.favIcon.href = 'assets/favicon.ico';
    }
    
    Amplify.configure(environment.amplifyConfig);
    this.envname = environment.envname;
    this.backColor = environment.backColor;
    
  }

  ngOnInit() {
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData as CognitoUserInterface;
      console.log(authState);
      if(authState === 'signedin'){
        Auth.currentSession().then(res => {
          this.groups = res.getAccessToken().decodePayload()['cognito:groups'];

          this.defaultAvatarImg = "/assets/profile_template.jpeg";

          const response = res;

          this._ngZone.run(() => {
            this.router.navigateByUrl('/home');

            const accessToken = response.getAccessToken().getJwtToken();

            this.service.getUser(accessToken).subscribe(
              data => {
                this.morningUser = <MorningUser>data;
              },
              err => {
                this.error = "Unable to receive user details";
              }
            )
          });

          

        }).catch((error) => {
          this.groups.push("INVALID");
        })
      } else {
        this.ref.detectChanges();
      }
      
    })

    
  }

  ngOnDestroy() {
    return onAuthUIStateChange;
  }
}
