import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';

import { RecipeRoutingModule } from './recipe-routing.module';
import { DefaultComponent } from './default/default.component';
import { IconsModule } from '../icons/icons.module';
import { MenuComponent } from './menu/menu.component';
import { OverviewComponent } from './overview/overview.component';
import { DefaultMachineComponent } from './default-machine/default-machine.component';
import { CreateRecipeComponent } from './create-recipe/create-recipe.component';
import { RoasterRecipesComponent } from './roaster-recipes/roaster-recipes.component'

@NgModule({
  declarations: [DefaultComponent, MenuComponent, OverviewComponent, DefaultMachineComponent, CreateRecipeComponent, RoasterRecipesComponent],
  imports: [
    CommonModule,
    RecipeRoutingModule,
    HttpClientModule,
    IconsModule,
    FormsModule,
    ReactiveFormsModule 
  ],
  exports: [CreateRecipeComponent, RoasterRecipesComponent]
})
export class RecipeModule { }
