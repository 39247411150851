export class Roaster {

    public id: number;
    public name: String;
    public about: String;
    public url: URL;
    public country: String;
    public city: String;
    public status: any;
    public logoUrl: String;
    public coverUrl: String;
    public featured: boolean;
    public featuredOrder: number;
    public hidden: boolean;
    public isPartner: boolean;

    public isApproved(){
        if(this.status==="APPROVED"){
            return true;
        }

        return false;
    }

    // constructor(){
    //     this.id=null;
    //     this.name=null;
    // }
}